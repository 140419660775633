<template>
  <div>
    <!-- 头部 -->
    <div class="wHeader">暂时不用</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 头部
.wHeader {
  position: fixed;
  width: 100%;
  height: @wHeight_header;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  overflow: hidden;
}
</style>