<template>
  <div>
    <!-- 侧边导航栏 isOpen-控制是否展示二级导航 -->
    <div class="wSidebar" :class="{ isOpen: collapse }">
      <!-- 一级导航 -->
      <div class="wSidebar-classA">
        <div class="wSCA-box">
          <!-- 首页按钮 -->
          <div class="wSCA-index">
            <div class="wSCA-indexBtn" @click="wToRoot">
              <img class="wSCA-indexIcon" src="@/assets/image/wy_icon001.png" alt="首页" draggable="false" />
            </div>
          </div>
          <!-- 导航列表 -->
          <div class="wSCA-nav">
            <vue-scroll>
              <div class="wSCAN-list">
                <template v-for="(item, index) in items">
                  <!-- wActvie 控制选中值 -->
                  <div class="wSCAN-item" :class="item.index == onRoutes_classA ? 'wActive' : ''" :key="index" @click="wToNavPath(`${item.index}/${item.subs[0].index}`)">
                    <i class="wSCANI-icon" :class="item.icon"></i>
                    <div class="wSCANI-text">{{ item.title }}</div>
                  </div>
                </template>
              </div>
            </vue-scroll>
          </div>
        </div>
      </div>
      <!-- 二级导航 -->
      <div class="wSidebar-classB" :class="{ isOpen: collapse }">
        <div class="wSCB-box">
          <!-- 导航列表 -->
          <div class="wSCB-nav">
            <vue-scroll>
              <div class="wSCBN-list">
                <div v-for="(item, index) in items" :key="index">
                  <template v-if="item.index == onRoutes_classA">
                    <div v-for="(item01, index01) in item.subs" class="wSCBN-item" :class="item01.index == onRoutes_classB ? 'wActive' : ''" :key="index01" @click="wToNavPath(`${item.index}/${item01.index}`)">
                      <div class="wSCBNI-text">{{ item01.title }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </vue-scroll>
          </div>
        </div>
      </div>
      <div class="zClearBoth"></div>
    </div>
  </div>
</template>

<script>
import { pub_sidebarArr } from '@/network/wApi.js'

export default {
  data() {
    return {
      // items: [],
    }
  },
  computed: {
    items() {
      console.log('zaza')
      console.log(this.$store.state.sidebarArr)
      return this.$store.state.sidebarArr
    },
    // 侧边栏二级导航是否关闭
    collapse() {
      return this.$store.state.sidebarClassB
    },
    // 当前路由的地址-和侧边栏的index做选中操作
    onRoutes_classA() {
      // console.log(this.$route.path);
      // let wPathAll = this.$route.path;
      let wPathArr = this.$route.path.split('/')
      let wClassAPath = wPathArr[2] || ''
      let wClassBPath = wPathArr[3] || ''
      // console.log(wClassAPath);

      // 判断是否有二级
      for (let i = 0; i < this.items.length; i++) {
        // 针对首页做处理或者other模块的页面
        if (wClassAPath == 'index' || wClassAPath == 'other') {
          // 修改是否有二级导航
          this.$store.commit('sidebarClassB', 2)
          break
        }

        // 存在选中值
        if (wClassAPath == this.items[i].index) {
          let itemsSubs = this.items[i].subs
          if (itemsSubs && itemsSubs.length > 0) {
            // 有二级导航
            // 修改是否有二级导航
            this.$store.commit('sidebarClassB', 1)
          } else {
            // 没有二级导航
            // 修改是否有二级导航
            this.$store.commit('sidebarClassB', 2)
          }
          break
        }
      }

      return wClassAPath
    },
    onRoutes_classB() {
      // console.log(this.$route.path);
      let wPathAll = this.$route.path
      let wPathArr = wPathAll.split('/')
      let wClassBPath = wPathArr[3]
      // console.log(wClassBPath);
      return wClassBPath
    }
  },
  created() {
    // let wSidebarArr = JSON.parse(localStorage.getItem("LOGININFO")).wSidebarArr;
    // console.log(this.$store.state.sidebarArr);
    // // 赋值侧边导航数据
    // this.items = [];
    // this.$store.state.sidebarArr.forEach((item) => {
    //   let isExist = wSidebarArr.findIndex((value) => {
    //     return value == item.id;
    //   });
    //   if (isExist != -1) {
    //     console.log(item);
    //     this.items.push(item);
    //   }
    // });

    // this.items = this.$store.state.sidebarArr;

    // this.$store.commit("sidebarArr", res);

    // 请求函数
    pub_sidebarArr().then(res => {
      // this.items = res;
      console.log('导航栏信息',res);
      this.$store.commit('sidebarArr', res)
    })
    // // 监听事件
    // this.$on("updateSidebar", (res) => {
    //   console.log("updateSidebar-更新侧边导航数据");
    //   // 请求函数
    //   pub_sidebarArr().then((res) => {
    //     this.items = res;
    //   });
    // });
  },
  mounted() {},
  methods: {
    /**
     * wToRoot
     * 回到主页
     */
    wToRoot() {
      this.$router.push({ path: '/' })
      // 修改是否有二级导航
      this.$store.commit('sidebarClassB', 2)
    },

    /**
     * wToNavPath
     * 一级导航和二级导航页面跳转
     * wP_path 页面路径
     */
    wToNavPath(wP_path) {
      let wUrlPath = `/admin/${wP_path}`
      // 页面跳转
      this.$router.push({ path: wUrlPath })
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/css/publicStyle.less';

// 侧边导航栏
.wSidebar {
  position: fixed;
  width: @wWidth_sidebar_c;
  top: @wHeight_header;
  left: 0;
  bottom: 0;
  z-index: 10;
  transition: all 0.3s;
  user-select: none;

  &.isOpen {
    width: @wWidth_sidebar_o;
    transition: all 0.3s;
  }

  // 一级导航
  .wSidebar-classA {
    display: block;
    width: @wWidth_sidebar_c;
    height: 100%;
    float: left;
    // background-color: #333844;
    background-color: #ffffff;
    border-right: 1px solid #eee;
    box-sizing: border-box;

    .wSCA-box {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;

      // 首页
      .wSCA-index {
        display: flex;
        width: 100%;
        height: 80px;
        justify-content: center;
        align-items: center;
        background-color: @wColor_theme;

        .wSCA-indexBtn {
          flex: none;
          display: flex;
          // width: 36px;
          // height: 36px;
          // border-radius: 50%;
          width: 80%;
          height: 80%;
          border-radius: 5px;
          background-color: @wColor_theme;
          color: #ffffff;
          font-size: 16px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;

          .wSCA-indexIcon {
            flex: none;
            width: 20px;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.3);
          }
        }
      }

      // 导航列表
      .wSCA-nav {
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        bottom: 0;

        .wSCAN-list {
          display: block;
          width: 100%;

          .wSCAN-item {
            display: flex;
            width: 100%;
            height: 44px;
            line-height: 44px;
            padding-left: 16px;
            box-sizing: border-box;
            font-size: @wFontSize;
            color: #c9cfd5;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            &:hover {
              color: #ffffff;
              background-color: @wColor_theme;

              .wSCANI-icon {
                color: #ffffff;
              }
              .wSCANI-text {
                color: #ffffff;
              }
            }

            &.wActive {
              // color: @wColor_theme;
              // background-color: #ffffff;
              color: #ffffff;
              background-color: @wColor_theme;

              .wSCANI-icon {
                color: #ffffff;
              }
              .wSCANI-text {
                color: #ffffff;
              }
            }

            .wSCANI-icon {
              flex: none;
              color: #999999;
              margin-right: 5px;
              font-size: @wFontSize + 2px;
            }

            .wSCANI-text {
              flex: auto;
              color: #333333;
              line-height: inherit;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  // 二级导航
  .wSidebar-classB {
    display: none;
    width: @wWidth_sidebar_c;
    height: 100%;
    float: left;
    background-color: #ffffff;
    border-right: 1px solid #eee;
    box-sizing: border-box;

    &.isOpen {
      display: block;
    }

    .wSCB-box {
      display: block;
      width: 100%;
      height: 100%;
      padding: 50px 0 10px;
      box-sizing: border-box;

      // 导航列表
      .wSCB-nav {
        display: block;
        width: 100%;
        height: 100%;

        .wSCBN-list {
          display: block;
          width: 100%;
          padding: 0 5px;
          box-sizing: border-box;

          .wSCBN-item {
            display: block;
            width: 100%;
            line-height: 20px;
            padding: 10px 5px;
            box-sizing: border-box;
            text-align: center;
            margin-bottom: 8px;
            color: #666;
            cursor: pointer;
            font-size: @wFontSize;

            &:hover {
              color: #333333;
              background-color: #f6f8fb;
              border-radius: 4px;
            }

            &.wActive {
              color: @wColor_theme;
            }
          }
        }
      }
    }
  }
}
</style>
