<template>
  <div>
    <!-- 公共头部 -->
    <wHeader></wHeader>
    <!-- 侧边导航 -->
    <wSidebar></wSidebar>
    <!-- 主体内容 isOpen-控制是否展示二级导航 -->
    <div class="wHome-main" :class="{ isOpen: collapse }">
      <transition name="fade" :appear="true" mode="out-in">
        <!-- <keep-alive> -->
        <template v-if="isCrumbs">
          <router-view></router-view>
        </template>
        <template v-else>
          <wPageCrumbs>
            <router-view></router-view>
          </wPageCrumbs>
        </template>
        <!-- </keep-alive> -->
      </transition>
    </div>
  </div>
</template>

<script>
import wHeader from "@/components/public/Header.vue";
import wSidebar from "@/components/public/Sidebar.vue";
import wPageCrumbs from "@/components/layout/PageCrumbs.vue";
export default {
  components: {
    wHeader,
    wSidebar,
    wPageCrumbs,
  },
  data() {
    return {};
  },
  computed: {
    // 侧边栏是否关闭
    collapse() {
      return this.$store.state.sidebarClassB;
    },
    // 监听是否是首页
    isIndex() {
      let wPathAll = this.$route.path;
      let wPathArr = wPathAll.split("/");
      let wClassAPath = wPathArr[2] || "";
      return wClassAPath == "index";
    },
    // 监听是否拥有面包屑导航
    isCrumbs() {
      return this.$route.meta.crumbs ? true : false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 主体内容
.wHome-main {
  position: fixed;
  top: @wHeight_header;
  left: @wWidth_sidebar_c;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: @wColor_bg;
  transition: all 0.3s;

  &.isOpen {
    left: @wWidth_sidebar_o;
    transition: all 0.3s;
  }
}
</style>
